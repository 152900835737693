import type { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';

export async function checkStoreState(to: Route, from: Route, next: NavigationGuardNext) {
  if (store.getters['auth/isLoggedIn']) {
    if (!store.getters.hasCompany) {
      await store.dispatch('getUserAndCompanies');
    }
    if (store.getters.hasCompany) {
      if (store.getters.companies.length === 1) {
        next({ name: 'Dashboard', params: { companyNameSlug: store.getters.companies[0].nameSlug } });
      } else {
        next({ name: 'SelectCompany', query: to.query });
      }
    } else {
      next({ name: 'Register2', query: to.query });
    }
  } else {
    if (to.query.invitationToken) {
      if (to.query.hasAccount === '1') {
        next({ path: '/login', query: { invitationToken: to.query.invitationToken } });
      } else {
        next({ name: 'Register1', query: to.query });
      }
    } else {
      next({ name: 'Login', query: { redirect: to.fullPath } });
    }
  }
}

export async function loadComponentChunk(importFn: () => Promise<any>) {
  try {
    return await importFn();
  } catch (error: any) {
    // Handle vite chunk loading errors
    // This may be due to a new deployment or a network problem
    const isChunkError = error?.message?.includes('Failed to fetch dynamically imported module');

    if (isChunkError && typeof window !== 'undefined' && window.sessionStorage) {
      const timeNow = Date.now();
      const lastReloadTime = window.sessionStorage.getItem('systima-app-reload');
      const lastReloadTimeParsed = lastReloadTime ? parseInt(lastReloadTime) : null;

      // check for previous reload time not to reload infinitely
      if (!lastReloadTimeParsed || lastReloadTimeParsed + 60000 < timeNow) {
        window.sessionStorage.setItem('systima-app-reload', timeNow.toString());
        window.location.reload();
      }
    }

    throw error;
  }
}
