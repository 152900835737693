import api from '@/utils/api';
import type { BulkVatCodeUpdateBody, VatResponse } from '@/types/schema';

export const VATService = {
  getVatList(companyId: string, date: string): Promise<VatResponse[]> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vats`,
      params: {
        date,
      },
    });
  },
  getVat(companyId: string, code: string, date: string): Promise<VatResponse> {
    return api({
      method: 'get',
      url: `companies/${companyId}/vats/${code}`,
      params: {
        date,
      },
    });
  },
  bulkVatCodeUpdate(companyId: string, data: BulkVatCodeUpdateBody): Promise<void> {
    return api({
      method: 'post',
      url: `companies/${companyId}/vats/bulk_documents_update`,
      data,
    });
  },
};
